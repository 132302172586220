import React, { useState } from "react";
import { Button } from "components/Button/Button";
import { Input } from "components/Form";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { toast } from "react-hot-toast";
import Pagination from "react-js-pagination";
import ReactSelect from "react-select";
import { dateFormat } from "utils";
import { getCourseCategory } from "services/master";
import {
  getactivecourseskyc,
  getkycstudents,
} from "services/KYC/KYCcontroller";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton } from "@mui/material";

const StudentKYCDetails = () => {
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [allCourseTypes, setAllCourseTypes] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  if (!allCourses) {
    
  }
  const paginationHandler = (page) => {
    setActivePage(page);
    getKYCStudents(page);
  };

  const getKYCStudents = async (page = 1) => {
    try {
      showLoader();
      const res = await getkycstudents({
        courseid: selectedCourse.value,
        search: userEmail,
        page,
      });
      hideLoader();

      if (res.code === 200) {
        const students = res.data.students?.rows || [];
        const totalStudents = res.data.students?.count || 0;
        setRecord(students);
        setTotal(totalStudents);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      hideLoader();
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const searchHandler = () => {
    if (!selectedCourse && !userEmail)  {
      toast.error("Please Select a Course or Enter Email")
    }
    else{
      setActivePage(1);
      getKYCStudents(1);

    }
  };

  React.useLayoutEffect(() => {
    const getAllCourseTypes = async () => {
      try {
        showLoader();
        const res = await getCourseCategory();
        hideLoader();

        if (res.code === 200) {
          setAllCourseTypes(
            res.data.courseCategories?.map((data) => {
              return { label: data.name, value: data.id };
            }) || []
          );
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        hideLoader();
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    const getAllActiveCourses = async () => {
      try {
        showLoader();
        const res = await getactivecourseskyc();
        hideLoader();

        if (res.code === 200) {
          setAllCourses(
            res.data.courses?.map((data) => {
              return { label: data.CourseTitle, value: data.CourseID };
            }) || []
          );
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        hideLoader();
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    getAllCourseTypes();
    getAllActiveCourses();
  }, []);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Student KYC Details</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Student KYC Details</h4>
            </div>
            <div className="SearchBox mt-3" style={{ width: "87%" }}>
              <div className="row w-100">
                <div className="col-sm-5 mui-auto-complete">
                  <ReactSelect
                    className={`w-100 react-select`}
                    placeholder="Select Course"
                    options={allCourses}
                    value={selectedCourse}
                    onChange={(data) => {
                      setSelectedCourse(data);
                    }}
                    isSearchable={true}
                  />
                </div>
                <div className={`col-form-label py-0 col-sm-5`}>
                  <Input
                    className={"form-control"}
                    placeholder="Enter User Email Address or Phone"
                    value={userEmail}
                    onChange={({ target }) => setUserEmail(target.value)}
                    type="text"
                  />
                </div>
                <div className="col-sm-1">
                  <Button onClick={searchHandler}>Search</Button>
                </div>
              </div>
            </div>
            <hr className="border border-danger" />
            {loader}
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="TableList overflow-auto table-responsive p-0">
                  <table className="position-relative">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Student Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loader &&
                        record.map((student, index) => (
                          <tr key={index}>
                            <td>{++index + (activePage - 1) * 10}</td>
                            <td>{`${student.firstName} ${student.middleName} ${student.lastName}`}</td>
                            <td>{student.EmailID}</td>
                            <td>{student.Mobile}</td>
                            <td>
                              {/* <IconButton aria-label="profile" className="px-2 py-0" variant="outlined">
                                  <PersonIcon sx={{ color: "#4971B2" }} />
                                  <Link
                                        to={`/student-management/view-profile/${user?.mobile}}`}
                                        />
                                </IconButton> */}
                              <Link
                                to={`/kyc-management/student-kyc-action/${student?.Mobile || student?.EmailID}`}
                                type="acl"
                                role={652473}
                              >
                                <IconButton
                                  aria-label="profile"
                                  className="px-2 py-0"
                                  variant="outlined"
                                >
                                  <PersonIcon sx={{ color: "#4971B2" }} />
                                </IconButton>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {!loader && !record.length && (
                    <div>
                      <center><NotFound  /></center>
                    </div>
                  )}
                </div>
                <div >
                  {total > 10 ? (
                    <div className="Pagination">
                      <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => paginationHandler(page)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudentKYCDetails;
